<template>
    <el-container v-if="!state.loaderEnabledGen">
      <el-main class='pt-0' style="padding-right: 0 !important;">
        <el-container>
          <el-main class='' style='padding-left: 13px !important; padding-top: 20px;'>
            <affTicket v-if="!state.loaderEnabled" :objTicket="state.valTicket" :key="state.valTicket" />
          </el-main>
          <el-aside width="350px">
            <chat :deltaHauteur="state.hauteurChat" class="m-ticket" :style="`border-radius: 5px; cursor: default; position: fixed;right: 15px;width: 350px;top:${state.topChat}px`"  dis_type_entity="ticket"  :dis_knum_entity="state.selTicket" :key="state.selTicket || state.hauteurChat" v-if="state.selTicket > 0" />
          </el-aside>
        </el-container>

      </el-main>
    </el-container>

  <div class="card m-20" v-if="state.loaderEnabledGen">
    <el-empty class="" description="Veuillez sélectionner un ticket dans la liste" />
  </div>

</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import affTicket from "@/views/still/deploy/affTicket.vue";
import chat from "@/views/still/chat/chat.vue";
import * as moment from "moment";
import { useRouter } from "vue-router";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "deploy-overview",
  components: {affTicket, chat},
  setup() {
    
    const router = useRouter();

    //moment.default.locale("fr");
    const state = reactive({
      loaderEnabledGen : true,
      loaderEnabled: true,
      myList: [] as any,
      valTicket : [] as any,
      selTicket : 0,
      topChat : 20,
      hauteurChat: 50,
    });

    onMounted(async () => {
      if (router.currentRoute.value.params.ticid) {
        affTicket(router.currentRoute.value.params.ticid);
        state.loaderEnabledGen = false;
      }
      
    });

    const affTicket = async (ticID) => {
      state.selTicket = ticID;
      let myList = await mAxiosApi.prototype.getAxios("/getTicket/" + ticID);

      myList.results.tic_staging.forEach((el) => {
        el.fichiers = JSON.parse(el.fichiers);
      })

      state.valTicket = myList.results;
      state.loaderEnabled = false;
    }

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };

    return {
      state,
      formatDate, 
      affTicket
    };
  },
});
</script>
<style>

</style>